import BaseModel from "@/models/base-model.js";

export default class Color extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "name",
      minLen: 3,
      // maxLen: 10,
      validationRules: {
        required: true,
        minLength: true,
        // maxLength: true,
        // invalidFeedback: "custom",
      },
      // invalidFeedback:
      //   "This field is required and should have 3-10 characters.",
    },
    sku_code: {
      type: "text",
      title: "Kode SKU",
      label: "sku_code",
      minLen: 3,
      validationRules: {
        required: false,
        minLength: false,
      },
    },
    description: {
      type: "textarea",
      title: "Deskripsi",
      label: "description",
    },
    code_hexa: {
      type: "text",
      title: "Warna Palet",
      label: "code_hexa",
      minLen: 3,
      maxLen: 10,
      validationRules: {
        required: true,
        minLength: true,
        maxLength: true,
      },
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "colors";
}
